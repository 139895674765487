<template>
  <LayoutManager>
    <div class="pr-main" id="qa-profile-view">
      <Loader v-if="isLoading" />
      <b-row class="h-100" v-if="!isLoading">
        <b-col cols="8">
          <user-alerts v-if="getAuthProfile.is_mfa_enabled === false" />
          <div class="pr-det__div h-100">
            <b-row class="m-0 pr-sections" v-for="(m, i) in modules" :key="i">
              <b-col
                cols="8"
                :class="{
                  'text-left': language !== 'ar',
                  'text-right': language === 'ar'
                }"
              >
                <label class="pr-label__title"> {{ m.title }}</label>
              </b-col>
              <b-col
                cols="4"
                :class="{
                  'p-0': true,
                  'text-right': language !== 'ar',
                  'text-left': language === 'ar'
                }"
              >
                <b-button class="ob-btn ob-btn-secondary" @click="onEdit(m)">{{
                  m.isActive ? $t("general.close") : $t("general.edit")
                }}</b-button>
              </b-col>
              <div class="pr-mod__sec" v-if="m.isActive">
                <div
                  class="pr-sec__div"
                  v-if="m.code_name === 'account_preference'"
                >
                  <div
                    class="sp-module__acc"
                    v-for="(s, j) in m.sections"
                    :key="j"
                  >
                    <b-card no-body class="">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-media
                          right-align
                          vertical-align="center"
                          @click="moduleClick(s, i, j)"
                        >
                          <template #aside>
                            <img
                              :src="
                                s.active
                                  ? require('@/assets/images/icons/arrow-up-black.svg')
                                  : require('@/assets/images/icons/arrow-down-black.svg')
                              "
                              alt="Arrow icon"
                              class="mr-3 pr-3"
                            />
                          </template>
                          <h5 class="mt-0">{{ s.name }}</h5>
                        </b-media>
                      </b-card-header>
                      <b-collapse
                        :id="`accordion-${i}-${j}`"
                        :visible="s.active"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <ul>
                            <li
                              v-for="(ss, k) in s.sub_sec"
                              :key="k"
                              :class="{ active: s.active }"
                            >
                              <div class="w100">
                                <label> {{ ss.des }}</label>
                                <div
                                  class="pr-sub__div"
                                  v-if="s.code_name === 'signin'"
                                >
                                  <p class="pr-sub__helper">
                                    {{ ss.last_login }}
                                  </p>
                                  <b-button
                                    class="ob-btn ob-btn-primary text-capitalize"
                                    >Update Password</b-button
                                  >
                                </div>
                                <div
                                  class="pr-sub__div d-flex align-items-end pr-sub__switch"
                                  v-if="s.code_name === 'account_visibility'"
                                >
                                  <div>
                                    <label>Make account private</label>
                                  </div>
                                  <b-form-checkbox
                                    switch
                                    size="lg"
                                    v-model="ss.is_account_private"
                                  >
                                  </b-form-checkbox>
                                </div>
                                <div
                                  class="pr-sub__div pr-sub__switch"
                                  v-if="s.code_name === 'alerts'"
                                >
                                  <div
                                    class="d-flex align-items-end mb-3"
                                    v-for="(al, l) in ss.alerts"
                                    :key="l"
                                  >
                                    <div style="width:40%">
                                      <label>{{ al.displayName }}</label>
                                    </div>
                                    <b-form-checkbox
                                      switch
                                      size="lg"
                                      v-model="al.value"
                                    >
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <div
                                  class="pr-sub__div pr-sub__switch"
                                  v-if="s.code_name === 'language'"
                                >
                                  <b-row>
                                    <b-col cols="7">
                                      <Select
                                        label="Update current region(country)"
                                        placeholder="Country"
                                        :dropdownArr="countries"
                                        v-model="ss.region"
                                        @click="onSelect($event, 'region', ss)"
                                      />
                                    </b-col>
                                    <b-col cols="6">
                                      <Select
                                        label="Current Time Zone"
                                        placeholder="Timezone"
                                        :dropdownArr="timezoneArr"
                                        v-model="ss.timezone"
                                        @click="
                                          onSelect($event, 'timezone', ss)
                                        "
                                      />
                                    </b-col>
                                  </b-row>
                                </div>
                                <div
                                  class="pr-sub__div pr-sub__switch"
                                  v-if="s.code_name === 'bandwidth'"
                                >
                                  <b-row>
                                    <b-col cols="6">
                                      <Select
                                        label="Preferred streaming quality"
                                        placeholder="bandwidth"
                                        :dropdownArr="streamingArr"
                                        v-model="ss.streaming_qualtiy"
                                        @click="
                                          onSelect(
                                            $event,
                                            'streaming_qualtiy',
                                            ss
                                          )
                                        "
                                      />
                                    </b-col>
                                  </b-row>
                                </div>
                                <div
                                  class="pr-sub__div"
                                  v-if="s.code_name === 'account_management'"
                                >
                                  <a class="pr-sub__acc suspend"
                                    >Suspend Account</a
                                  >
                                  <a class="pr-sub__acc delete"
                                    >Delete Account</a
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
                <div class="pr-sec__div" v-if="m.code_name === 'goals_skills'">
                  <div class="pr-lang">
                    <h2
                      :class="{
                        'ob-ed__title w-40': true,
                        'text-right': language === 'ar'
                      }"
                    >
                      {{ $t("onboarding.goals.hint") }}
                    </h2>
                    <div class="ob-lg__textarea">
                      <Textarea
                        rows="6"
                        :limit="160"
                        :hasLimit="true"
                        v-model.trim="form.interests"
                        :value="form.interests"
                        :postOb="true"
                        :placeholder="$t('onboarding.goals.placeholder')"
                      />
                    </div>
                    <div class="ob-ed__cta d-flex align-items-center mt-3">
                      <b-button
                        :class="{
                          'ob-btn ob-btn-primary text-capitalize': true,
                          'mr-3': language !== 'ar',
                          'ml-3': language === 'ar'
                        }"
                        :disabled="
                          form.interests === allProfile.interests ||
                            isProfileLoading
                        "
                        @click="onProfileUpdate"
                      >
                        {{ $t("general.save") }}</b-button
                      >
                      <p class="mb-0 pr-sec__save" v-if="isSaved">
                        <img
                          src="@/assets/images/check.svg"
                          alt="Check icon"
                          width="14"
                          height="14"
                        />{{ $t("my_profile.goals.saved") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="pr-sec__div" v-if="m.code_name === 'language'">
                  <div class="pr-lang">
                    <h2
                      :class="{
                        'ob-ed__title w-40': true,
                        'text-right': language === 'ar'
                      }"
                    >
                      {{ $t("my_profile.language.subheading") }}
                    </h2>
                    <div class="ob-ed__main text-left">
                      <b-row>
                        <b-col cols="7">
                          <Select
                            :label="$t('my_profile.language.label')"
                            :placeholder="$t('data.language.en')"
                            :dropdownArr="allLanguage"
                            :value="m.language ? m.language.name : null"
                            @click="onSelect($event, 'language', m)"
                          /> </b-col
                      ></b-row>
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="pr-right__sec h-100" v-if="!isEdit">
            <div class="pr-right__img"></div>
            <div class="pr-right__name">
              <b-img
                :src="
                  allProfile.profile_image_url
                    ? allProfile.profile_image_url
                    : require('@/assets/images/menu/avatar.svg')
                "
                rounded="circle"
                width="84"
                height="84"
                alt="avatar"
              />
              <h5 :title="allProfile.name">{{ allProfile.name }}</h5>
            </div>
            <div class="pr-right__sec">
              <ul>
                <li>
                  <label>{{ $t("my_profile.details.gender") }}</label>
                  <p>
                    {{
                      allProfile.gender
                        ? allProfile.gender.id === 3
                          ? allProfile.udf_gender
                          : allProfile.gender.name
                        : "-"
                    }}
                  </p>
                </li>
                <li>
                  <label>{{ $t("my_profile.details.email") }}</label>
                  <p>{{ allProfile.email ? allProfile.email : "-" }}</p>
                </li>
                <li>
                  <label>{{ $t("onboarding.location.country.hint") }}</label>
                  <p>
                    {{ allProfile.country ? allProfile.country.name : "-" }}
                  </p>
                </li>
                <li>
                  <label>{{ $t("my_profile.details.age") }}</label>
                  <p>{{ allProfile.age ? allProfile.age.name : "-" }}</p>
                </li>
                <li>
                  <label>{{ $t("my_profile.details.job_role.title") }}</label>
                  <p>
                    {{
                      allProfile.role
                        ? allProfile.role.id === 47
                          ? `${allProfile.role.name} - ${allProfile.udf_role} `
                          : allProfile.role.name
                        : "-"
                    }}
                  </p>
                </li>
                <li>
                  <label>{{ $t("onboarding.job.duration.label") }}</label>
                  <p>
                    {{
                      allProfile.role_tenure ? allProfile.role_tenure.name : "-"
                    }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="pr-right__btn">
              <b-button class="ob-btn ob-btn-primary" @click="editProfile">{{
                $t("my_profile.details.title")
              }}</b-button>
            </div>
          </div>
          <div class="pr-right__sec h-100" v-else>
            <div class="pr-right__img"></div>
            <div class="pr-right__name edit">
              <b-img
                :src="
                  allProfile.profile_image_url
                    ? allProfile.profile_image_url
                    : require('@/assets/images/menu/avatar.svg')
                "
                rounded="circle"
                width="84"
                height="84"
              />
              <div class="pr-img__edit" @click="openUploadModal">
                <!-- <input type="file" id="profile" class="invisible" /> -->
                <label
                  ><span>{{
                    $t("my_profile.details.edit_picture")
                  }}</span></label
                >
              </div>

              <b-modal
                id="edit-pic-modal"
                centered
                size="md"
                :no-close-on-backdrop="true"
                title="Profile photo"
              >
                <template #modal-header="{close}">
                  <h5>{{ $t("my_profile.details.update_picture") }}</h5>
                  <img
                    src="@/assets/images/close.svg"
                    alt="close"
                    @click="close()"
                  />
                </template>
                <div class="edit-profile__div cd-main">
                  <div class="">
                    <b-row
                      class="h-100 d-flex align-items-center justify-content-center ep-div__sec"
                    >
                      <div class="w-100" v-if="!onUpload">
                        <img
                          :src="
                            allProfile.profile_image_url
                              ? allProfile.profile_image_url
                              : require('@/assets/images/menu/avatar.svg')
                          "
                          alt="avatar"
                          width="280"
                          id="profile-pic"
                          v-show="false"
                        />
                      </div>
                      <div class="w-100" v-else>
                        <img
                          :src="newImage"
                          alt="image"
                          width="280"
                          id="new-profile-pic"
                          v-show="false"
                        />
                      </div>
                    </b-row>
                  </div>
                </div>
                <template v-slot:modal-footer>
                  <div class="d-flex w-100 ep-footer row">
                    <div class="ep-footer__icons col-6">
                      <div class="text-center mr-4">
                        <input
                          type="file"
                          id="profile"
                          class="invisible"
                          accept=".png,.jpg,.jpeg"
                          @change="onUploadChange"
                        />
                        <label for="profile">
                          <img
                            src="@/assets/images/settings/icons/camera.svg"
                            alt="camera"
                          />
                          <span class="d-block">{{
                            $t("general.upload")
                          }}</span>
                        </label>
                      </div>
                      <div class="text-center" v-b-modal.delete-profile-modal>
                        <img
                          src="@/assets/images/settings/icons/bin.svg"
                          alt="bin"
                        />
                        <span class="d-block">{{ $t("general.delete") }}</span>
                      </div>
                    </div>
                    <div class="ep-footer__cta col-6 text-right">
                      <button
                        class="btn ob-btn ob-btn-primary text-capitalize"
                        @click="onPicSave"
                        :disabled="isPicUploading"
                      >
                        {{ $t("general.save") }}
                      </button>
                    </div>
                  </div>
                </template>
              </b-modal>
              <b-modal id="delete-profile-modal" centered size="md">
                <template #modal-header>
                  <b-icon
                    icon="question-circle-fill"
                    style="color:#0043ac"
                  ></b-icon>
                  <h5>{{ $t("my_profile.delete_picture.title") }}</h5>
                </template>
                <div>
                  <p>{{ $t("my_profile.delete_picture.subtitle") }}</p>
                </div>
                <template #modal-footer="{hide}">
                  <b-button class="ob-btn ob-btn-primary" @click="deletePic"
                    >{{ $t("general.delete") }} ➞
                  </b-button>
                  <b-button class="ob-btn ob-btn-secondary" @click="hide()">{{
                    $t("general.save")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
            <div class="pr-edit__div mt-4">
              <div v-if="isProfileLoading" class="pr-edit__loader">
                <Loader />
              </div>
              <b-row v-else>
                <b-col cols="12">
                  <Input
                    id="first_name"
                    :label="$t('my_profile.details.first_name')"
                    :placeholder="$t('my_profile.details.first_name')"
                    v-model="form.given_name"
                    :value="form.given_name"
                    :isAppend="true"
                  />
                </b-col>
                <b-col cols="12">
                  <Input
                    id="last_name"
                    :label="$t('my_profile.details.last_name')"
                    :placeholder="$t('my_profile.details.last_name')"
                    v-model="form.family_name"
                    :value="form.family_name"
                    :isAppend="true"
                  />
                </b-col>
                <b-col cols="12">
                  <Select
                    :label="$t('my_profile.details.gender')"
                    :placeholder="$t('my_profile.details.gender')"
                    :dropdownArr="allConstants.genders"
                    :value="form.gender ? form.gender.name : null"
                    @click="onSelect($event, 'gender', form)"
                  />
                </b-col>
                <b-col cols="12" v-if="showGenderText">
                  <Input
                    id="gender"
                    :label="$t('my_profile.gender.other.please_specify')"
                    placeholder="Gender"
                    :required="true"
                    v-model="form.udf_gender"
                  />
                </b-col>
                <b-col cols="12">
                  <Input
                    id="email"
                    :label="$t('my_profile.details.email')"
                    :placeholder="$t('my_profile.details.email')"
                    :value="form.email"
                    v-model="form.email"
                    :isAppend="true"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="12">
                  <Select
                    :label="$t('my_profile.details.age')"
                    :placeholder="$t('my_profile.details.age')"
                    :dropdownArr="allConstants.ages"
                    :value="form.age ? form.age.name : null"
                    @click="onSelect($event, 'age', form)"
                  />
                </b-col>
                <b-col cols="12">
                  <Select
                    :label="$t('onboarding.location.country.hint')"
                    :placeholder="$t('onboarding.location.country.hint')"
                    :dropdownArr="countries"
                    :value="form.country ? form.country.name : null"
                    @click="onSelect($event, 'country', form)"
                  />
                </b-col>
                <b-col cols="12">
                  <Select
                    :label="$t('onboarding.job.current_role.hint')"
                    :placeholder="$t('onboarding.job.current_role.hint')"
                    :dropdownArr="roles"
                    :value="form.role ? form.role.name : null"
                    @click="onSelect($event, 'role', form)"
                  />
                </b-col>
                <b-col cols="12" v-if="showRoleText">
                  <Input
                    id="role"
                    :label="$t('onboarding.job.other.please_specify')"
                    placeholder="Role"
                    :required="true"
                    v-model="form.udf_role"
                  />
                </b-col>
                <b-col cols="12">
                  <Select
                    :label="$t('onboarding.job.duration.hint')"
                    :placeholder="$t('onboarding.job.duration.hint')"
                    :dropdownArr="roleTenures"
                    :value="form.role_tenure ? form.role_tenure.name : null"
                    @click="onSelect($event, 'role_tenure', form)"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="pr-right__btn">
              <b-button
                class="ob-btn ob-btn-secondary mr-3"
                @click="onCancel()"
                >{{ $t("general.cancel") }}</b-button
              >
              <b-button
                class="ob-btn ob-btn-primary"
                @click="onProfileUpdate"
                >{{ $t("general.save") }}</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import LayoutManager from "@/components/Layout/LayoutManager.vue";
import Input from "@/components/WrapperComponents/Input";
import Select from "@/components/WrapperComponents/Select";
import Loader from "@/components/WrapperComponents/Loader";
import Textarea from "@/components/WrapperComponents/Textarea";
import Cropper from "cropperjs";
import axios from "axios";
import UserAlerts from "@/components/UserAlerts.vue";
import moment from "moment";

export default {
  components: { LayoutManager, Input, Loader, Select, Textarea, UserAlerts },
  data() {
    return {
      pr_info: {},
      isEdit: false,
      form: {},
      isLoading: true,
      streamingArr: ["Low", "High", "Auto"],
      search: "",
      showRoleText: false,
      showGenderText: false,
      timezoneArr: [
        "GMT+0	 {{ allProfile && allProfile.name }}Greenwich Mean Time",
        "GMT +1 (London)",
        "GMT+2	Eastern European Time"
      ],
      isSaved: false,
      isProfileLoading: false,
      croppedImage: null,
      onUpload: false,
      newImage: null,
      fileName: "",
      locale: "",
      modules: [],
      isPicUploading: false
    };
  },
  computed: {
    ...mapGetters([
      "allAuth",
      "allConstants",
      "allProfile",
      "roles",
      "countries",
      "roleTenures",
      "allConfig",
      "allLanguage",
      "language",
      "showCompleteProfile",
      "getAuthProfile"
    ])
  },
  watch: {
    allProfile() {
      this.updateProfile();
    },
    locale(val) {
      this.$i18n.i18next.changeLanguage(val);
      moment.locale(val === "zh" ? "zh-cn" : val);
      this.getModules(val);
    },
    language(v) {
      this.locale = v;
    }
  },
  mounted() {
    // Check if user profile details are already available
    // if not make an API call to get the user profile details
    if (Object.keys(this.allProfile).length === 0) {
      this.$store.dispatch("getProfile");
    }
    this.$store.dispatch("getAllConstants");

    this.updateProfile();
    this.getModules(this.language);
  },
  methods: {
    getModules(lang) {
      this.modules = [
        {
          title: this.$i18n.t("my_profile.language.heading"),
          code_name: "language",
          isActive: false,
          language: this.allLanguage.find(l => l.id === lang)
        },
        {
          title: this.$i18n.t("onboarding.goals.heading"),
          code_name: "goals_skills",
          isActive: false,
          sections: [
            {
              name: this.$i18n.t("my_profile.goals.goals"),
              active: false,
              code_name: "goals"
            },
            {
              name: this.$i18n.t("my_profile.goals.skills"),
              active: false,
              code_name: "skills"
            }
          ]
        }
      ];
    },
    moduleClick(m, i, j) {
      this.modules[i].sections.forEach((mod, index) => {
        if (index !== j) {
          mod.active = false;
        }
      });
      m.active = !m.active;
      this.$root.$emit("bv::toggle::collapse", `accordion-${i}-${j}`);
    },
    onEdit(m) {
      this.modules.forEach(mod => {
        if (m.code_name === "goals_skills") {
          this.form = JSON.parse(JSON.stringify(this.pr_info));
        }
        if (mod.code_name !== m.code_name) {
          mod.isActive = false;
        }
      });
      m.isActive = !m.isActive;
    },
    editProfile() {
      this.modules.forEach(mod => {
        mod.isActive = false;
      });
      this.isEdit = true;
      this.form = JSON.parse(JSON.stringify(this.pr_info));
      if (this.form.role && this.form.role.id === 47) {
        this.showRoleText = true;
      }
      if (this.form.gender && this.form.gender.id === 3) {
        this.showGenderText = true;
      }
    },
    onCancel() {
      this.isEdit = false;
    },
    onSave() {
      this.pr_info = this.form;
      this.onCancel();
    },
    updateProfile() {
      this.isLoading = false;
      this.pr_info = {
        given_name: this.allProfile.given_name,
        family_name: this.allProfile.family_name,
        gender: this.allProfile.gender,
        email: this.allProfile.email,
        country: this.allProfile.country,
        age: this.allProfile.age,
        role: this.allProfile.role,
        role_tenure: this.allProfile.role_tenure,
        interests: this.allProfile.interests,
        udf_role: this.allProfile.udf_role,
        udf_gender: this.allProfile.udf_gender
      };
      if (this.allProfile.profile_image_url) {
        let urlArr = this.allProfile.profile_image_url.split("/");
        this.fileName = urlArr[urlArr.length - 1];
      }
    },
    getDisplayName(n) {
      if (n.match("_") !== null) {
        n = n.replace("_", " ");
      } else if (n === "dob") {
        n = "Year of birth";
      } else if (n.indexOf("years") !== -1) {
        n = "How long have you been in your current role";
      }
      return n;
    },
    onSelect(e, type, s) {
      s[type] = e;
      if (type === "role") {
        if (e.id === 47) {
          this.showRoleText = true;
        } else {
          this.showRoleText = false;
        }
      }
      if (type === "gender") {
        if (e.id === 3 || (this.form.role && this.form.role.id === 3)) {
          this.showGenderText = true;
        } else {
          this.showGenderText = false;
        }
      }
      if (type === "language") {
        this.locale = e.id;
        this.modules[0].language = e;
        this.$store.dispatch("updateProfile", { language: e.id });
      }
    },
    addInterest(t) {
      t.active = !t.active;
    },
    onProfileUpdate() {
      let arr = ["role", "age", "role_tenure", "gender", "country"],
        obj = {};
      Object.keys(this.form).forEach(f => {
        if (arr.includes(f)) {
          obj[f] = this.form[f] ? this.form[f].id : null;
        } else {
          obj[f] = this.form[f];
        }
      });
      this.isSaved = true;
      this.isProfileLoading = true;
      this.$store
        .dispatch("updateProfile", obj)
        .then(() => {
          this.isEdit = false;
          if (this.showCompleteProfile) {
            this.$store.commit("SET_SHOW_COMPLETE_PROFILE", false);
          }
          setTimeout(() => {
            this.isSaved = false;
            this.isProfileLoading = false;
          }, 3000);
          // this.createToast(
          //   "Profile details updated successfully",
          //   null,
          //   "success"
          // );
        })
        .catch(() => {
          this.isSaved = false;
          this.isProfileLoading = false;
        });
    },
    initCropper(image) {
      this.croppedImage = new Cropper(image, {
        minContainerHeight: 270,
        background: false,
        guides: false,
        cropBoxResizable: false
      });
    },
    onPicSave() {
      this.isPicUploading = true;
      this.croppedImage.getCroppedCanvas().toBlob(blob => {
        let file = new File([blob], this.fileName, {
            type: blob.type
          }),
          formData = new FormData();
        formData.append("profile_image", file);
        axios
          .put(`${this.allConfig.baseURL}/profile/picture`, formData, {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          })
          .then(res => {
            this.isPicUploading = false;
            this.updateProfilePic(res.data.profile_image_url);
            this.onUpload = false;
            this.$bvModal.hide("edit-pic-modal");
            // this.createToast(
            //   "Profile image updated successfully",
            //   null,
            //   "success"
            // );
          })
          .catch(err => {
            this.isPicUploading = false;
            this.$store.commit("SET_ERRORS", err.response.data);
          });
      });
    },
    onUploadChange(e) {
      let file = e.target.files[0];
      this.onUpload = true;
      this.newImage = URL.createObjectURL(file);
      this.fileName = file.name;
      setTimeout(() => {
        this.croppedImage.destroy();
        this.initCropper(document.getElementById("new-profile-pic"));
      }, 10);
    },
    updateProfilePic(data) {
      let profile = this.allProfile;
      profile["profile_image_url"] = data;
      this.$store.commit("SET_USER_PROFILE", profile);
    },
    deletePic() {
      axios
        .delete(`${this.allConfig.baseURL}/profile/picture`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.updateProfilePic(res.data.profile_image_url);
          this.onUpload = false;
          this.$bvModal.hide("delete-profile-modal");
          // this.createToast(
          //   "Profile image deleted successfully",
          //   null,
          //   "success"
          // );
        })
        .catch(err => {
          this.$store.commit("SET_ERRORS", err.response.data);
        });
    },
    openUploadModal() {
      this.onUpload = false;
      this.$bvModal.show("edit-pic-modal");
      setTimeout(() => {
        if (this.croppedImage) {
          this.croppedImage.destroy();
        }
        let image = document.getElementById("profile-pic");
        this.initCropper(image);
      }, 5);
    }
  }
};
</script>
<style lang="scss">
.pr-main {
  width: 100%;
  padding: 20px 40px;
  .pr-left__row {
    margin: 0;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .pr-left,
    .pr-right {
      text-align: left;
      padding: 20px 0;
      h4 {
        padding-left: 10px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #0043ac;
        font-weight: 500;
      }
      .pr-left__sec {
        margin-top: 20px;
      }
    }
    .pr-right {
      padding: 20px 15px;
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #0043ac;
        text-align: left;
        padding-bottom: 20px;
        border-bottom: 1px solid #0043ac;
        margin-bottom: 20px;
      }
      .pr-right__sec {
        .pr-right__det {
          .pr-img__edit {
            width: 64px;
            height: 64px;
            border-radius: 100%;
            position: absolute;
            text-align: center;
            overflow: hidden;
            span {
              font-size: 12px;
              font-weight: 500;
              color: #fff;
              line-height: 16px;
              background: rgba(0, 0, 0, 0.5);
              width: 100%;
              bottom: 0px;
              position: absolute;
              left: 0;
              cursor: pointer;
            }
          }
          .pr-det__div {
            border-bottom: 1px solid #e8e8e8;
            padding: 10px 0;
            .row {
              align-items: center;
            }
            .pr-det__form {
              .media {
                padding-left: 16px;
                .pr-det__note {
                  background: rgba(0, 67, 172, 0.15);
                  border-radius: 4px;
                  padding: 13px 16px;
                  h5 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.2px;
                    color: rgba(0, 0, 0, 0.87);
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
        label {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.1px;
          color: #25282b;
          margin-bottom: 0;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.87);
          margin-bottom: 0;
        }
      }
    }
  }
  .pr-det__div {
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 12px 22px 32px;
    .pr-sections {
      border-bottom: 1px solid #e8e8e8;
      padding: 12px 0;
      display: flex;
      align-items: center;
      .text-left {
        .pr-label__title {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: #0043ac;
          margin-bottom: 0;
        }
      }
      .text-right {
        .ob-btn {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 1.25px;
          font-weight: 500;
        }
      }
      .pr-mod__sec {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        margin-top: 15px;
      }
    }
  }
  .pr-sec__save {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #0043ac;
    display: flex;
    align-items: center;
    img {
      margin-right: 4px;
      width: 14px;
      height: 14px;
    }
  }
}
#edit-pic-modal {
  .modal-header,
  .modal-footer {
    align-items: center;
    border: 1px solid #e8e8e8;
  }
  .modal-header {
    padding: 25px;
    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: #0043ac;
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
    }
  }
  .edit-profile__div {
    min-height: 270px;
    .ep-div__sec {
      margin: 23px 53px;
    }
    #profile-pic {
      max-width: 100%;
      display: block;
    }
  }
  .ep-footer {
    .ep-footer__icons {
      display: flex;
      input {
        width: 0;
      }
      .text-center,
      label {
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      .b-icon {
        font-size: 150%;
      }
      span {
        color: #25282b;
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
}
</style>
